import React from "react";
import styled, { css } from "styled-components";

import { HexButton, HexSpinner, HexTooltip } from "../../hex-components";
import { useUserForMagic } from "../../hooks/magicHooks";
import { MagicOnboardingPopover } from "../../magic/MagicOnboardingPopover";
import { CyData } from "../../util/cypress.js";
import { MagicIcon } from "../icons/CustomIcons";

const Wrapper = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex: none;
  position: absolute;
  left: -20px;
  visibility: hidden;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      visibility: visible;
    `}
`;

interface ProjectMetadataMagicGenerateProps {
  isLoading: boolean;
  onAction: () => void;
  buttonCyData?: string;
  isVisible: boolean;
}

export const ProjectMetadataMagicGenerate: React.ComponentType<ProjectMetadataMagicGenerateProps> =
  React.memo(function ProjectMetadataMagicGenerate({
    buttonCyData,
    isLoading,
    isVisible,
    onAction,
  }) {
    const { onboardedToMagic } = useUserForMagic();

    return (
      <Wrapper $isVisible={isVisible}>
        {isLoading ? (
          <HexSpinner data-cy={CyData.MAGIC_LOADING_SPINNER} />
        ) : (
          <HexTooltip content="Generate with Magic" position="top">
            <MagicOnboardingPopover onAccept={onAction}>
              <HexButton
                data-cy={buttonCyData}
                extraSmall={true}
                icon={<MagicIcon />}
                minimal={true}
                onClick={onboardedToMagic ? onAction : undefined}
              />
            </MagicOnboardingPopover>
          </HexTooltip>
        )}
      </Wrapper>
    );
  });
