import { gql } from "@apollo/client";
import { Intent } from "@blueprintjs/core";
import { HexType, OrgRole } from "@hex/common";
import React, { useCallback } from "react";

import { HexMenuItem } from "../../hex-components/HexMenuItem.js";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import { useProjectContext } from "../../util/projectContext.js";
import { useToaster } from "../common/Toasts.js";
import { StarInactiveIcon } from "../icons/CustomIcons.js";

import {
  StarHexMutationVariables,
  useStarHexMutation,
} from "./StarMenuItem.generated.js";

gql`
  mutation starHex($hexId: HexId!, $starred: Boolean!) {
    starHex(hexId: $hexId, starred: $starred) {
      id
      starredByViewer
    }
  }
`;

export interface StarMenuItemProps {
  isStarred: boolean | undefined;
}

export const StarMenuItem: React.ComponentType<StarMenuItemProps> = React.memo(
  function StarMenuItem({ isStarred }: StarMenuItemProps) {
    const toaster = useToaster();
    const { hexId, hexType } = useProjectContext();

    const currentUser = useCurrentUser();
    const currentUserOrgRole = currentUser?.orgRole;
    const isAnonymousOrEmbedded =
      currentUserOrgRole === OrgRole.ANONYMOUS ||
      currentUserOrgRole === OrgRole.EMBEDDED_USER;

    const [starHex] = useStarHexMutation();

    const isComponent = hexType === HexType.COMPONENT;

    const toggleStarCallback: React.MouseEventHandler = useCallback(
      (evt) => {
        evt.preventDefault();
        evt.stopPropagation();

        const variables: StarHexMutationVariables = {
          hexId,
          starred: !isStarred,
        };
        starHex({
          variables,
          optimisticResponse: {
            __typename: "Mutation",
            starHex: {
              id: hexId,
              __typename: "Hex",
              starredByViewer: !isStarred,
            },
          },
        }).catch((e) => {
          console.error(e);
          toaster.show({
            message: isStarred
              ? "Error unstarring Project"
              : "Error starring Project",
            intent: Intent.DANGER,
          });
        });
      },
      [starHex, toaster, hexId, isStarred],
    );

    return (
      <HexMenuItem
        disabled={isAnonymousOrEmbedded}
        icon={<StarInactiveIcon />}
        text={
          isStarred
            ? "Remove from your favorites"
            : `Favorite this ${isComponent ? "component" : "project"}`
        }
        onClick={toggleStarCallback}
      />
    );
  },
);
