import { FetchResult, gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import {
  DOCS_LINKS,
  HexColor,
  OrgRole,
  PlatformRole,
  isOrgRoleSuperset,
} from "@hex/common";
import React, { useCallback, useMemo } from "react";
import styled, { css, useTheme } from "styled-components";

import {
  HexButton,
  HexCheckbox,
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexPopover,
  HexTooltip,
  Txt,
} from "../../hex-components";
import { BUTTON_OVERRIDE_SMALL } from "../../hex-components/HexButton.js";
import { useUserForMagic } from "../../hooks/magicHooks";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import {
  MinimalCategory,
  MinimalStatus,
  SchemaStatusSource,
} from "../../hooks/useProjectLabelsForHex";
import { useGetCategoriesAndStatusesQuery } from "../../hooks/useProjectLabelsForHex.generated";
import { useToggleState } from "../../hooks/useToggleState";
import { ORG_ID } from "../../orgs";
import {
  ThemeOverrideWrapper,
  ThemeResetVariables,
} from "../../ThemeWrapper.js";
import { useCreateStatusMutation } from "../admin/OrgStatusesPanel.generated";
import { LabelFormDialog } from "../admin/project-organization/LabelFormDialog";
import { LabelPanelCreateItemParams } from "../admin/project-organization/LabelPanelContainer";
import { DocsLink } from "../common/DocsLink.js";
import { AppliedCategories } from "../common/labels/applied-labels/AppliedCategories";
import { CollapsedAppliedLabelsWrapper } from "../common/labels/applied-labels/styled.js";
import { CategoryLabel } from "../common/labels/CategoryLabel";
import { StatusLabel } from "../common/labels/StatusLabel";
import {
  AddIcon,
  InfoIcon,
  LockIcon,
  ReviewInProgressIcon,
  SingleChevronDownIcon,
} from "../icons/CustomIcons";
const DROPDOWN_WIDTH = 120;
export const STATUS_LABEL = "status-label";
export const CATEGORIES_LABEL = "categories-label";

gql`
  fragment StatusFragment on Status {
    id
    name
    color
    description
    inLibrary
    endorsed
    icon
  }

  fragment CategoryFragment on Category {
    id
    name
    color
    description
  }
`;

let createDialogKey = 0;

export interface ProjectStatusProps {
  allStatuses: readonly (MinimalStatus & { enforcesReview: boolean })[];
  appliedStatus?: MinimalStatus | null;
  disabled?: boolean;
  muted?: boolean;
  onSelectStatus: (newStatus: MinimalStatus | null) => void;
  readonly?: boolean;
  resourceType: "hex" | "data";
  statusInheritedFrom?: SchemaStatusSource;
  condensed?: boolean;
  requiresStatusSelection?: boolean;
}

export const ProjectStatus: React.FunctionComponent<ProjectStatusProps> = ({
  allStatuses,
  appliedStatus,
  condensed = false,
  disabled = false,
  muted = false,
  onSelectStatus,
  readonly = false,
  requiresStatusSelection = false,
  resourceType,
  statusInheritedFrom,
}) => {
  const [
    createDialogOpen,
    ,
    { setFalse: closeCreateDialog, setTrue: openCreateDialog },
  ] = useToggleState(false);
  const theme = useTheme();

  const { magicEnabled } = useUserForMagic();

  const [createStatusMutation] = useCreateStatusMutation();

  const onCreateItemPromise = useCallback(
    (params: LabelPanelCreateItemParams): Promise<FetchResult> => {
      return createStatusMutation({
        variables: {
          orgId: ORG_ID,
          name: params.name,
          color: params.color as HexColor,
          inLibrary: params.inLibrary ?? false,
          description: params.description,
          endorsed: params.endorsed ?? false,
          enforcesReview: params.enforcesReview ?? false,
          icon: params.icon ?? null,
        },
      });
    },
    [createStatusMutation],
  );

  const currentUser = useCurrentUser();
  const isOrgAdmin =
    currentUser?.orgRole === OrgRole.ADMIN && ORG_ID === currentUser.org.id;
  const isPlatformAdmin = currentUser?.platformRole === PlatformRole.ADMIN;
  const isAdmin = isOrgAdmin || isPlatformAdmin;

  const nonEndorsedStatuses = useMemo(
    () => allStatuses.filter((o) => !o.endorsed),
    [allStatuses],
  );

  const endorsedStatuses = useMemo(
    () => allStatuses.filter((o) => o.endorsed),
    [allStatuses],
  );

  const isManagerOrAbove =
    currentUser != null &&
    isOrgRoleSuperset(currentUser.orgRole, OrgRole.MANAGER);

  const labelOptions = useMemo(
    () => (
      <>
        {nonEndorsedStatuses.map((status) => {
          return (
            <LabelOption
              key={status.name}
              active={appliedStatus?.id === status.id}
              text={
                <StatusLabel
                  descriptionTooltipProps={{ placement: "right" }}
                  status={status}
                />
              }
              // eslint-disable-next-line react/jsx-no-bind -- passing status
              onClick={() => onSelectStatus(status)}
            />
          );
        })}
        <EndorsedStatusHeader isManagerOrAbove={isManagerOrAbove} />
        {endorsedStatuses.length === 0 && <EmptyStatuses>None</EmptyStatuses>}
        {endorsedStatuses.map((status) => {
          return (
            <LabelOption
              key={status.name}
              active={appliedStatus?.id === status.id}
              disabled={!isManagerOrAbove}
              text={
                <EndorsedStatusRow
                  resourceType={resourceType}
                  status={status}
                />
              }
              // eslint-disable-next-line react/jsx-no-bind -- passing status
              onClick={() => onSelectStatus(status)}
            />
          );
        })}
        {isAdmin && (
          <>
            <HexMenuDivider />
            <LabelOption
              key="create"
              text={
                <AddStatusButton>
                  <AddIcon />
                  New status...
                </AddStatusButton>
              }
              onClick={openCreateDialog}
            />
          </>
        )}
      </>
    ),
    [
      appliedStatus,
      endorsedStatuses,
      isAdmin,
      isManagerOrAbove,
      nonEndorsedStatuses,
      onSelectStatus,
      openCreateDialog,
      resourceType,
    ],
  );

  const clearStatus = useCallback(() => onSelectStatus(null), [onSelectStatus]);

  const existingItemNames = useMemo(
    () => new Set(allStatuses.map((item) => item.name)),
    [allStatuses],
  );

  const hasLibraryStatuses = useMemo(
    () => allStatuses.some((item) => item.inLibrary && !item.endorsed),
    [allStatuses],
  );

  if ((disabled || readonly) && !appliedStatus) {
    return null;
  }

  return (
    // We need to wrap this component in a base theme since we want the portal to be created as a un-custom themed popover
    // Below we render the target of the popover in the context where this component is rendered (either custom or base)
    <ThemeOverrideWrapper theme="base">
      <LabelSection className={STATUS_LABEL}>
        <HexPopover
          captureDismiss={true}
          content={
            <LabelOptionsList>
              {appliedStatus &&
                !statusInheritedFrom &&
                !requiresStatusSelection && (
                  <>
                    <HexMenuDivider />
                    <LabelOption
                      intent="danger"
                      text="Clear status"
                      onClick={clearStatus}
                    />
                  </>
                )}
              {labelOptions}
            </LabelOptionsList>
          }
          disabled={disabled || readonly}
          minimal={true}
          placement="bottom-start"
        >
          <ThemeOverrideWrapper
            // We only want to use the custom theme for the child component if we're rendering this component in a custom theme context
            theme={theme.isCustomTheme ? "custom" : "base"}
          >
            <PickerButton
              $noSelection={!appliedStatus}
              $readonly={readonly}
              className={Classes.INPUT}
              disabled={disabled}
              minimal={true}
              rightIcon={
                !appliedStatus ? <SingleChevronDownIcon iconSize={14} /> : null
              }
              small={true}
            >
              {appliedStatus ? (
                <StatusLabel
                  condensed={condensed}
                  inheritedFrom={statusInheritedFrom}
                  muted={muted}
                  status={appliedStatus}
                />
              ) : (
                "Add status"
              )}
            </PickerButton>
          </ThemeOverrideWrapper>
        </HexPopover>
        <LabelFormDialog
          key={createDialogKey++}
          defaultStatusId={null}
          existingItemNames={existingItemNames}
          isOpen={createDialogOpen}
          itemTypeName="status"
          magicAllowed={magicEnabled}
          showLibraryConfig={hasLibraryStatuses}
          onClose={closeCreateDialog}
          onCreateItem={onCreateItemPromise}
        />
      </LabelSection>
    </ThemeOverrideWrapper>
  );
};

export const EndorsedStatusHeader: React.FunctionComponent<{
  isManagerOrAbove: boolean;
}> = ({ isManagerOrAbove }) => {
  return (
    <HexMenuDivider
      title={
        <HexTooltip
          content={
            <>
              Endorsed statuses indicate that certain content or data has been
              endorsed for wider use and can be trusted.{" "}
              <DocsLink to={DOCS_LINKS.Endorsements}>Learn more</DocsLink>
            </>
          }
          hoverCloseDelay={200}
          interactionKind="hover"
          placement="right"
        >
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            <div
              css={`
                margin-right: 3px;
              `}
            >
              Endorsed Statuses
            </div>
            {isManagerOrAbove ? <InfoIcon /> : <LockIcon />}
          </div>
        </HexTooltip>
      }
    />
  );
};

export const EndorsedStatusRow: React.FunctionComponent<{
  status: MinimalStatus & { enforcesReview: boolean };
  resourceType: "hex" | "data";
  className?: string;
}> = ({ className, resourceType, status }) => {
  const enforcesReview = resourceType === "hex" && status.enforcesReview;

  return (
    <HexTooltip
      content={
        <div
          css={css`
            flex-direction: column;
            display: flex;
            gap: 3px;
          `}
        >
          {status?.description != null && status?.description !== "" && (
            <>
              <Txt fontSize="small">{status.description}</Txt>
              <br />
            </>
          )}
          <Txt fontColor="muted" fontSize="extra_small">
            <LockIcon iconSize={8} /> Only editable by workspace admins &
            managers
          </Txt>
          {enforcesReview && (
            <Txt fontColor="muted" fontSize="extra_small">
              <ReviewInProgressIcon iconSize={8} /> Review required for
              publishing
            </Txt>
          )}
        </div>
      }
      // eslint-disable-next-line react/jsx-no-bind -- render target
      renderTarget={({
        isOpen: _tooltipIsOpen,
        ref: tooltipRef,
        ...tooltipProps
      }) => (
        <div
          {...tooltipProps}
          ref={tooltipRef}
          className={className}
          css={css`
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3px;
          `}
        >
          <StatusLabel
            descriptionTooltipProps={{ placement: "right" }}
            hideTooltip={true}
            status={{ ...status, description: null }}
          />
          {enforcesReview && <ReviewInProgressIcon />}
        </div>
      )}
    />
  );
};

interface ProjectCategoriesProps {
  allCategories: readonly MinimalCategory[];
  appliedCategories: readonly MinimalCategory[];
  disabled?: boolean;
  muted?: boolean;
  onSelectCategory: (category: MinimalCategory, applied: boolean) => void;
  readonly?: boolean;
}

export const ProjectCategories: React.FunctionComponent<
  ProjectCategoriesProps
> = ({
  allCategories,
  appliedCategories,
  disabled = false,
  muted = false,
  onSelectCategory,
  readonly = false,
}) => {
  const theme = useTheme();
  const appliedCategoryIds = useMemo(
    () => appliedCategories.map((c) => c.id),
    [appliedCategories],
  );
  const isCategoryApplied = useCallback(
    (category: MinimalCategory) => {
      return appliedCategoryIds.includes(category.id);
    },
    [appliedCategoryIds],
  );

  if ((disabled || readonly) && appliedCategories.length === 0) {
    return null;
  }

  return (
    // We need to wrap this component in a base theme since we want the portal to be created as a un-custom themed popover
    // Below we render the target of the popover in the context where this component is rendered (either custom or base)
    <ThemeOverrideWrapper theme="base">
      <LabelSection className={CATEGORIES_LABEL}>
        <HexPopover
          captureDismiss={true}
          content={
            <LabelOptionsList>
              {allCategories.map((category) => {
                return (
                  <LabelOption
                    key={category.name}
                    // shouldDismissPopover={false}
                    text={
                      <StyledCheckbox
                        checked={isCategoryApplied(category)}
                        labelElement={
                          <CategoryLabel
                            category={category}
                            descriptionTooltipProps={{
                              placement: "right",
                            }}
                          />
                        }
                      />
                    }
                    // eslint-disable-next-line react/jsx-no-bind -- in a loop
                    onClick={(e) => {
                      e.preventDefault();
                      onSelectCategory(category, isCategoryApplied(category));
                    }}
                  />
                );
              })}
            </LabelOptionsList>
          }
          disabled={disabled || readonly}
          minimal={true}
          placement="bottom-start"
        >
          <ThemeOverrideWrapper
            // We only want to use the custom theme for the child component if we're rendering this component in a custom theme context
            theme={theme.isCustomTheme ? "custom" : "base"}
          >
            <PickerButton
              $noSelection={appliedCategories.length === 0}
              $readonly={readonly}
              className={Classes.INPUT}
              disabled={disabled}
              minimal={true}
              rightIcon={
                appliedCategories.length === 0 ? (
                  <SingleChevronDownIcon iconSize={14} />
                ) : null
              }
              small={true}
            >
              {appliedCategories && appliedCategories.length > 0 ? (
                <AppliedCategoryWrapper>
                  <AppliedCategories
                    appliedCategories={appliedCategories}
                    muted={muted}
                  />
                </AppliedCategoryWrapper>
              ) : (
                "Add categories"
              )}
            </PickerButton>
          </ThemeOverrideWrapper>
        </HexPopover>
      </LabelSection>
    </ThemeOverrideWrapper>
  );
};

interface ProjectLabelsProps {
  appliedCategories: readonly MinimalCategory[];
  appliedStatus: MinimalStatus | null;
  statusInheritedFrom?: SchemaStatusSource;
  display?: "all" | "categories" | "status";
  disabled?: boolean;
  muted?: boolean;
  onSelectCategory: (category: MinimalCategory, applied: boolean) => void;
  onSelectStatus: (newStatus: MinimalStatus | null) => void;
  readonly?: boolean;
  className?: string;
  resourceType: "hex" | "data";
}

export const ProjectLabels: React.FunctionComponent<ProjectLabelsProps> = ({
  appliedCategories,
  appliedStatus,
  className,
  disabled = false,
  display = "all",
  muted = false,
  onSelectCategory,
  onSelectStatus,
  readonly,
  resourceType,
  statusInheritedFrom,
}) => {
  const { data, loading } = useGetCategoriesAndStatusesQuery({
    variables: { orgId: ORG_ID },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const allOrgCategories = useMemo(
    () => [...(data?.orgById.categories ?? [])],
    [data],
  );

  const allOrgStatuses = useMemo(
    () => [...(data?.orgById.statuses ?? [])],
    [data],
  );

  const onSelectStatusCallback = useCallback(
    (newStatus: MinimalStatus | null) => {
      onSelectStatus(newStatus);
    },
    [onSelectStatus],
  );

  if (loading) {
    return <LoadingLabels />;
  } else if (allOrgStatuses.length === 0 && allOrgCategories.length === 0) {
    return null;
  }

  if (display === "categories" || allOrgStatuses.length === 0) {
    if (readonly && appliedCategories.length === 0) {
      return null;
    }
    return (
      <ProjectCategories
        allCategories={allOrgCategories}
        appliedCategories={appliedCategories}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        onSelectCategory={onSelectCategory}
      />
    );
  }

  if (display === "status" || allOrgCategories.length === 0) {
    if (readonly && !appliedStatus) {
      return null;
    }
    return (
      <ProjectStatus
        allStatuses={allOrgStatuses}
        appliedStatus={appliedStatus}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        resourceType={resourceType}
        statusInheritedFrom={statusInheritedFrom}
        onSelectStatus={onSelectStatusCallback}
      />
    );
  }

  if (readonly && appliedCategories.length === 0 && !appliedStatus) {
    return null;
  }

  return (
    <LabelLayout className={className}>
      <ProjectStatus
        allStatuses={allOrgStatuses}
        appliedStatus={appliedStatus}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        resourceType={resourceType}
        statusInheritedFrom={statusInheritedFrom}
        onSelectStatus={onSelectStatusCallback}
      />
      <ProjectCategories
        allCategories={allOrgCategories}
        appliedCategories={appliedCategories}
        disabled={disabled}
        muted={muted}
        readonly={readonly}
        onSelectCategory={onSelectCategory}
      />
    </LabelLayout>
  );
};

const LoadingLabels = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  height: 24px;
  padding-left: 3px;

  &::before,
  &::after {
    display: block;
    width: 60px;
    height: 3px;
    margin-right: 15px;

    background-color: ${({ theme }) => theme.activeColor};
    border-radius: ${({ theme }) => theme.borderRadius};

    content: "";
  }
`;

const LabelLayout = styled.div`
  display: flex;
  min-width: 0;
  transition: background-color ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};
`;

export const LabelSection = styled.div`
  ${ThemeResetVariables}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 0;
  padding: 0;

  &.${STATUS_LABEL} {
    flex-shrink: 0;
  }

  &.${CATEGORIES_LABEL} {
    flex-shrink: 1;
  }

  .${Classes.POPOVER_TARGET} {
    display: flex;
    min-width: 0;
  }
`;

const PickerButton = styled(HexButton)<{
  $readonly: boolean;
  $noSelection: boolean;
}>`
  ${ThemeResetVariables}

  &&&&&&&&${BUTTON_OVERRIDE_SMALL} {
    justify-content: space-between;
    width: 100%;
    height: auto;
    outline: none;
    padding: 0;

    .${Classes.BUTTON_TEXT} {
      min-width: 0;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    ${({ $noSelection }) =>
      $noSelection &&
      css`
        padding: 4px 6px;
        gap: 2px;
      `}

    ${({ $readonly }) =>
      $readonly &&
      css`
        &&&.${Classes.BUTTON} {
          &&.${Classes.BUTTON}:not(.${Classes.DISABLED}):hover {
            background-color: transparent;
          }
        }
      `}


      /** Spacing for collapsed categories wrapper, that is needed for the picker button  */
     &&&& ${CollapsedAppliedLabelsWrapper} {
      padding: 4px 6px;
    }
  }
`;

export const LabelOptionsList = styled(HexMenu)`
  ${ThemeResetVariables}
  min-width: ${DROPDOWN_WIDTH}px;
`;

export const LabelOption = styled(HexMenuItem)`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  padding-top: 0;
  padding-bottom: 0;
`;

export const AppliedCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -4px 0;
`;

const StyledCheckbox = styled(HexCheckbox)`
  display: flex;
  align-items: center;

  .${Classes.CONTROL_INDICATOR} {
    margin-top: 0;
  }
  .${Classes.POPOVER_TARGET} {
    display: flex;
  }
`;

const EmptyStatuses = styled.div`
  color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  border: 1px dashed ${({ theme }) => theme.borderColor.DEFAULT};
  line-height: 22px;
  padding: 0 7px;
  text-align: center;
`;

const AddStatusButton = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;
